import { Dispatch, SetStateAction } from "react";
import MenuHamburgerIcon from "assets/icons/menu-hamburger.svg";
import { HashContext } from "components/docs/shared-components/HashContext";
import { Link } from "gatsby";
import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import { Accordion } from "react-bootstrap";
import styled from "styled-components";
import { RouterLocation } from "types/types";
import MergeLogo from "../../../assets/svg/logos/merge/logo.svg";
import { TOP_NAV_HEIGHT } from "./MobileNavigationBarStyledComponents";
import MobileNavSectionEntry from "./MobileNavSectionEntry";
import { useActiveDocsLink } from "./useActiveDocsLink";
import useDocsMobileNavLinks from "./useDocsMobileNavLinks";
import InkeepSearchWrapper from "./InkeepSearchWrapper";

interface Props {
  location: RouterLocation;
  setIsInkeepVisible: Dispatch<SetStateAction<boolean>>;
}

const MobileOnlyNav = styled.nav`
  && {
    @media (min-width: 768px) {
      display: none !important;
    }
    position: fixed;
    top: 0px;
    z-index: 9999;
    width: 100%;
  }
`;

const MobileNavPlaceholder = styled.div`
  @media (min-width: 768px) {
    display: none !important;
  }
  width: 100%;
  height: ${TOP_NAV_HEIGHT}px;
`;

const MobileNavContainer = styled.div`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 0px var(--gray10);
  color: var(--gray90);
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    box-shadow: 0px 0.5px 0px var(--gray20);
  }
`;

const TopRow = styled.div`
  height: ${TOP_NAV_HEIGHT}px;
  width: 100%;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MergeDocsLogoSpan = styled.span`
  margin: 0;
  padding: 0;
`;

const TopNavButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  text-align: center;
`;

const HORIZONTAL_SEPARATOR_HEIGHT = 1; // px
const HORIZONTAL_SEPARATOR_MARGIN = 14; // px
const HorizontalSeparator = styled.div`
  width: 100%;
  height: ${HORIZONTAL_SEPARATOR_HEIGHT}px;
  margin: ${HORIZONTAL_SEPARATOR_MARGIN}px 0;
  background: linear-gradient(
    90deg,
    rgba(234, 238, 243, 0) 0%,
    var(--gray10) 15%,
    var(--gray10) 85%,
    rgba(234, 238, 243, 0) 100%
  );

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    height: 0.5px;
    background: linear-gradient(
      90deg,
      rgba(220, 226, 234, 0) 0%,
      var(--gray20) 15%,
      var(--gray20) 85%,
      rgba(220, 226, 234, 0) 100%
    );
  }
`;

// DropdownContainer height accounts for horizontal separator height and bottom margin, but not top,
// because its top margin is set to 0px.
const DROPDOWN_CONTAINER_HEIGHT_OFFSET =
  TOP_NAV_HEIGHT + HORIZONTAL_SEPARATOR_HEIGHT + HORIZONTAL_SEPARATOR_MARGIN;
const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100vh - ${DROPDOWN_CONTAINER_HEIGHT_OFFSET}px);
  background: #ffffff;
  z-index: 9999;
  overflow-y: auto;
`;

const UnorderedList = styled.ul`
  list-style: none;
  padding: 0;
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  > * + * {
    margin-top: 16px !important;
  }
`;

const PrimaryButtonLink = styled.a`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;
    width: 100%;
    font-weight: 600;
    font-size: 15px;
    border: none;
    line-height: 56px;
    margin: 0;
    padding: 0;
    background: #121314;
    color: #ffffff !important;
    border-radius: 6px;
    &:hover {
      color: #e6e6e6 !important;
    }
  }
`;

const SecondaryButtonLink = styled(PrimaryButtonLink)`
  && {
    background: #ffffff;
    color: #121314 !important;
    border: 1px solid var(--gray10);
    &:hover {
      background: #e6e6e6;
      color: #121314 !important;
    }
  }
`;

/**
 * Shows the navigation menu for mobile, a stacked list of links, separated into sections.
 * The menu weirdly may show secondary or primary links, with tertiary/quaternary or secondary
 * links nested under in an accordion. There's a few different cases for the included links
 * as a result. Aggressively memoized!
 */
const MobileNavigationBar = ({ location, setIsInkeepVisible }: Props) => {
  const { hash } = useContext(HashContext);
  const [showMobileNav, setShowMobileNav] = useState(false);
  const mobileNavSections = useDocsMobileNavLinks();
  const {
    primary: activePrimaryLink,
    secondary: activeSecondaryLink,
    tertiary: activeTertiaryLink,
  } = useActiveDocsLink(location);

  // Close the dropdown menu when location changes.
  useLayoutEffect(() => {
    setShowMobileNav(false);
  }, [location.pathname, hash]);

  // Button for opening/closing the whole menu
  const toggleButton = useMemo(
    () =>
      !showMobileNav ? (
        <TopNavButton
          type="button"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowMobileNav(true)}
        >
          <MenuHamburgerIcon />
        </TopNavButton>
      ) : (
        <TopNavButton
          type="button"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={() => setShowMobileNav(false)}
        >
          <span className="fe fe-x" style={{ fontSize: "24px", lineHeight: "24px" }} />
        </TopNavButton>
      ),
    [showMobileNav],
  );

  // Each of the links + accordians that make up the mobile nav contents
  const mobileNavItems = useMemo(
    () =>
      mobileNavSections.map((section) => (
        <React.Fragment key={section.header ?? "untitled-section"}>
          <MobileNavSectionEntry {...section} location={location} />
          {section.hasDividerAfter && <HorizontalSeparator />}
        </React.Fragment>
      )),
    [location, mobileNavSections],
  );

  /**
   * The default active key is the `eventKey` of the currently open accordion item. If we have a
   * tertiary link active, then that means the key that opens the accordion that contains the
   * tertiary link can have an eventKey that's either a secondary or primary link. If there's no
   * tertiary link active, then it means that the accordion we have open is showing secondary
   * links, and thus the default key must be a primary link.
   */
  const defaultActiveKey = activeTertiaryLink
    ? activeSecondaryLink?.linkTo ?? activePrimaryLink?.linkTo
    : activePrimaryLink?.linkTo;

  // This goes inside the mobile nav container after the top row
  const contents = showMobileNav && (
    <>
      <HorizontalSeparator style={{ marginTop: "0" }} />
      <DropdownContainer id="sidebarCollapse">
        <Accordion defaultActiveKey={defaultActiveKey}>
          <UnorderedList>{mobileNavItems}</UnorderedList>
        </Accordion>
        <ButtonContainer>
          <SecondaryButtonLink href="https://app.merge.dev/login">Log in</SecondaryButtonLink>
          <PrimaryButtonLink href="https://app.merge.dev/signup">Sign up</PrimaryButtonLink>
        </ButtonContainer>
      </DropdownContainer>
    </>
  );

  return (
    <>
      <MobileNavPlaceholder />
      <MobileOnlyNav>
        <MobileNavContainer>
          <TopRow>
            <MergeDocsLogoSpan>
              {toggleButton}
              <Link to="/" title="Merge Documentation" className="ml-4">
                <MergeLogo />
              </Link>
            </MergeDocsLogoSpan>
            <InkeepSearchWrapper
              setIsInkeepVisible={setIsInkeepVisible}
              placeholderText="Search..."
              showHotKeys={false}
            />
          </TopRow>
          {contents}
        </MobileNavContainer>
      </MobileOnlyNav>
    </>
  );
};

export default MobileNavigationBar;
