import React from "react";
import classNames from "classnames";
import styled from "styled-components";

import { APICategory, Badge } from "@merge-api/merge-javascript-shared";
import Tooltip from "components/generic/Tooltip";
import { HTTPMethod } from "../../../types/types";
import { palette } from "../../../styles/theme";
import { getAdditionalEndpointInfo } from "../utils/services";
import { DeprecationStage } from "../assets/constants";

type ResponseStatusBadgeProps = {
  responseCode: number | string;
  className?: string;
};

export const ResponseStatusBadge = ({ responseCode, className }: ResponseStatusBadgeProps) => {
  const isOKResponse = responseCode === 200 || responseCode === "200 OK";
  const text = isOKResponse ? "200 OK" : `${responseCode} Error`;

  return (
    <span
      className={classNames(
        "badge",
        isOKResponse ? "badge-soft-success" : "badge-soft-danger",
        className,
      )}
    >
      {text}
    </span>
  );
};

type HTTPMethodBadgeFilledProps = {
  method: HTTPMethod | string;
};

export const HTTPMethodBadgeFilled = ({ method }: HTTPMethodBadgeFilledProps) => {
  let style = "light";
  switch (method) {
    case HTTPMethod.GET:
      style = "primary";
      break;
    case HTTPMethod.POST:
      style = "success";
      break;
    case HTTPMethod.PUT:
      style = "info";
      break;
    case HTTPMethod.DELETE:
      style = "danger";
      break;
    case HTTPMethod.PATCH:
      style = "dark";
      break;
    case HTTPMethod.OPTIONS:
      style = "secondary";
      break;
    case HTTPMethod.HEAD:
      style = "light";
      break;
  }

  return (
    <span className={`badge badge-${style} http-method-badge`}>
      <b>{method}</b>
    </span>
  );
};

type HTTPMethodBadgeProps = {
  method: HTTPMethod;
  className?: string;
};
const HTTPMethodBadgeSpan = styled.span<HTTPMethodBadgeProps>`
  font-size: 12px;
  font-weight: 600;
  margin-right: 6px;
  color: ${(props) => {
    switch (props.method) {
      case HTTPMethod.GET:
        return "var(--blue40)";
      case HTTPMethod.POST:
        return "#00b187";
      case HTTPMethod.PATCH:
        return "var(--yellow50)";
      case HTTPMethod.DELETE:
        return "#ea0524";
      case HTTPMethod.PUT:
        return "var(--indigo50)";
      default:
        return palette.black;
    }
  }};
`;
export const HTTPMethodBadge = ({ method, className }: HTTPMethodBadgeProps) => (
  <HTTPMethodBadgeSpan method={method} className={className}>
    {method}
  </HTTPMethodBadgeSpan>
);

type HTTPMethodBadgeBackgroundProps = {
  method: string;
};
const HTTPMethodBadgeBackground = styled.div<HTTPMethodBadgeBackgroundProps>`
  background-color: ${(props) => {
    switch (props.method) {
      case HTTPMethod.GET:
        return "var(--blue0)";
      case HTTPMethod.POST:
        return "var(--teal0)";
      case HTTPMethod.PATCH:
        return "var(--yellow0)";
      case HTTPMethod.DELETE:
        return "#ea0524";
      case HTTPMethod.PUT:
        return "var(--indigo50)";
      default:
        return palette.black;
    }
  }};
  padding-left: 5px;
  padding-right: 5px;
`;

export const HTTPMethodMergeBadge = ({ method }: { method: string }) => {
  switch (method) {
    case "GET":
      return (
        <Badge size="sm" color="blue">
          GET
        </Badge>
      );
    case "POST":
      return (
        <Badge size="sm" color="teal">
          POST
        </Badge>
      );
    case "PATCH":
      return (
        <Badge size="sm" color="yellow">
          PATCH
        </Badge>
      );
    case "PUT":
      return (
        <Badge size="sm" color="indigo">
          PUT
        </Badge>
      );
    default:
      return null;
  }
};

export const HTTPMethodBadgeWithBackground = ({ method }: any) => {
  return (
    <HTTPMethodBadgeBackground method={method} className="pt-0.5 pb-0.5 rounded">
      {HTTPMethodBadge({ method, className: "mr-0 text-xs" })}
    </HTTPMethodBadgeBackground>
  );
};

type CommonModelBadgeProps = {
  commonModel: string;
};

export const CommonModelBadge = ({ commonModel }: CommonModelBadgeProps) => {
  let style = "light";
  switch (commonModel.split(".")[0]) {
    case APICategory.hris:
      style = "soft-primary";
      break;
    case APICategory.ats:
      style = "soft-secondary";
  }
  return <span className={`badge badge-${style} mr-2`}>{commonModel}</span>;
};

interface DeprecatedBadgeProps {
  /**
   * The actual content of the tooltip
   */
  tooltipContent?: React.ReactElement | string;
}
export const DeprecatedBadge = ({ tooltipContent }: DeprecatedBadgeProps) => {
  const mainBadge = (
    <Badge className="ml-2" size="md" color="yellow">
      Deprecated
    </Badge>
  );
  if (tooltipContent) {
    return <Tooltip content={tooltipContent}>{mainBadge}</Tooltip>;
  }
  return mainBadge;
};

export const ComingSoonBadge = () => {
  return (
    <Badge className="ml-2" color="slate" size="md">
      Coming soon
    </Badge>
  );
};

export const BetaBadge = () => {
  return (
    <Badge className="ml-2" color="blue" size="md">
      Beta
    </Badge>
  );
};

/**
 * Given a path or tag (eg "available-actions" or "/api/ats/v1/available-actions"), render null or a DeprecatedBadge.
 */
export const getBadgeForEndpoint = (endpointPathOrTag?: string) => {
  if (!endpointPathOrTag) {
    return null;
  }
  const additionalEndpointInfo = getAdditionalEndpointInfo(endpointPathOrTag);
  if (additionalEndpointInfo?.isBeta) {
    return <BetaBadge />;
  } else if (additionalEndpointInfo?.isComingSoon) {
    return <ComingSoonBadge />;
  }

  if (additionalEndpointInfo?.deprecationStage === DeprecationStage.DEPRECATED) {
    return <DeprecatedBadge tooltipContent={additionalEndpointInfo.deprecationTooltip} />;
  }
  return null;
};
