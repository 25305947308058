import React from "react";

type Props = {
  onClick?: () => void;
  children?: JSX.Element;
  style?: React.CSSProperties;
  className?: string;
  clickable?: boolean;
};
const ClickableContainer = ({ children, onClick, style, className, clickable }: Props) => (
  <span
    className={`${clickable ? "hover:cursor-pointer" : ""} ${className}`}
    style={style}
    onClick={onClick}
  >
    {children}
  </span>
);

export default ClickableContainer;
