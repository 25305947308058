import LINKS from "./links";
import { useCommonModelLinks } from "./useDocsNavLinks";

interface Link {
  text: string;
  linkTo: string;
}

interface DocsFooterLinkColumn {
  /**
   * Name of the column that appears in gray pretitle above it, defaults to
   * an empty string if nothing is provided
   */
  columnTitle?: string;

  /**
   * List of all bold big links to show, with optional sublinks too
   */
  links: Array<Link & { links?: Array<Link> }>;

  /**
   * If set to true, allows setting up an extra spacer column after the end of the column
   */
  spaceAfter?: true;
}

/**
 * A giant list of links & sublinks for the docs footer across all pages.
 * Reference only entries in LINKS for ease of use please! Doesn't show which link
 * is currently active, so we can get away with a more static list here.
 *
 * Returns an array of columns, each with links.
 */
const useDocsFooterLinks = (): Array<DocsFooterLinkColumn> => {
  const commonModelLinks = useCommonModelLinks();
  return [
    {
      columnTitle: "Get started",
      links: [
        LINKS.GET_STARTED_INTRODUCTION,
        LINKS.GET_STARTED_UNIFIED_API,
        LINKS.GET_STARTED_LINKED_ACCOUNT,
        LINKS.GET_STARTED_LINK,
        LINKS.SDKS,
      ],
      spaceAfter: true,
    },
    { columnTitle: "API reference", links: [LINKS.MERGE_API_BASICS, LINKS.SUPPLEMENTAL_DATA] },
    {
      links: [
        {
          text: "Merge ATS",
          linkTo: LINKS.ATS_OVERVIEW.linkTo,
          links: commonModelLinks.ats?.tertiaryLinks,
        },
      ],
    },
    {
      links: [
        {
          text: "Merge HRIS",
          linkTo: LINKS.HRIS_OVERVIEW.linkTo,
          links: commonModelLinks.hris?.tertiaryLinks,
        },
      ],
    },
    {
      links: [
        {
          text: "Merge Accounting",
          linkTo: LINKS.ACCOUNTING_OVERVIEW.linkTo,
          links: commonModelLinks.accounting?.tertiaryLinks,
        },
      ],
    },
    {
      links: [
        {
          text: "Merge Ticketing",
          linkTo: LINKS.TICKETING_OVERVIEW.linkTo,
          links: commonModelLinks.ticketing?.tertiaryLinks,
        },
      ],
    },
    {
      links: [
        {
          text: "Merge CRM",
          linkTo: LINKS.CRM_OVERVIEW.linkTo,
          links: commonModelLinks.crm?.tertiaryLinks,
        },
      ],
    },
    {
      links: [
        {
          text: "Merge File Storage",
          linkTo: LINKS.FILESTORAGE_OVERVIEW.linkTo,
          links: commonModelLinks.filestorage?.tertiaryLinks,
        },
      ],
    },
  ];
};

export default useDocsFooterLinks;
