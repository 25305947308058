module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"./src/assets/svg/favicon.svg","name":"Merge","short_name":"Merge","start_url":"/","background_color":"#121314","theme_color":"#FFFFFF","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f78134e9361481cf5db76360ff40d40a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intercom-spa/gatsby-browser.js'),
      options: {"plugins":[],"app_id":"ybk0pfck","include_in_development":true,"delay_timeout":0},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"srk2kyo"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WZ3TTB4","defaultDataLayer":{"type":"function","value":"()=>{function a(){try {return JSON.parse(decodeURIComponent(document.cookie.match('(^|;)\\\\s*cookie-settings\\\\s*=\\\\s*([^;]+)')?.pop() || \"{}\"))}catch(e){return {}}}const b = a();const f=(b[\"functional-cookies\"]||\"enabled\")===\"enabled\"?\"granted\":\"denied\";function g(){return arguments;}return g(\"consent\",\"update\",{ad_storage:(b[\"marketing-cookies\"]||\"enabled\")===\"enabled\"?\"granted\":\"denied\",analytics_storage:(b[\"performance-cookies\"]||\"enabled\")===\"enabled\"?\"granted\":\"denied\",functionality_storage:f,personalization_storage:f})}"},"includeInDevelopment":false,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
