import { Link } from "gatsby";
import React, { useEffect, useMemo, Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { palette } from "styles/theme";
import { RouterLocation } from "types/types";
import ChoiceLink from "../shared-components/ChoiceLink";
import LinkButton from "../shared-components/LinkButton";
import MergeLogo from "../../../assets/svg/logos/merge/logo.svg";
import { useActiveDocsLink } from "./useActiveDocsLink";
import { useDocsNavLinks } from "./useDocsNavLinks";
import useCommandKPressed from "../hooks/useCommandK";
import InkeepSearchWrapper from "./InkeepSearchWrapper";
import LINKS, { MERGE_HOME_PAGE } from "./links";
import NavigationDropDown from "./NavigationDropDown";

export const TOP_NAV_HEIGHT = 63; // px
const HORIZONTAL_SEPARATOR_HEIGHT = 1; // px
const SUB_NAV_HEIGHT = 42; // px
export const FULL_TOP_NAV_HEIGHT = 106; // px
const HEIGHT_TRANSITION = "height 0.25s";

const DesktopOnlyNav = styled.nav`
  @media (max-width: 767.98px) {
    display: none !important;
  }

  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
`;

const NavBarDiv = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 0px var(--gray10);
  height: ${(props) =>
    props.isExpanded
      ? TOP_NAV_HEIGHT + HORIZONTAL_SEPARATOR_HEIGHT + SUB_NAV_HEIGHT
      : TOP_NAV_HEIGHT}px;
  transition: ${HEIGHT_TRANSITION};

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    box-shadow: 0px 0.5px 0px var(--gray20);
  }
`;

const NavBarCollapsableHeightPlaceholderDiv = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  background: none;
  box-shadow: none;
  z-index: -1;
  height: ${(props) =>
    props.isExpanded
      ? TOP_NAV_HEIGHT + HORIZONTAL_SEPARATOR_HEIGHT + SUB_NAV_HEIGHT
      : TOP_NAV_HEIGHT}px;
  transition: ${HEIGHT_TRANSITION};
  @media (max-width: 767.98px) {
    display: none !important;
  }
`;

const TopRow = styled.div`
  height: ${TOP_NAV_HEIGHT}px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
`;

// Help the top nav compress more gracefully.
const GAP_ADJUSTMENT_THRESHOLD = 915; // px
const TopRowLeftSide = styled.div`
  @media (max-width: ${GAP_ADJUSTMENT_THRESHOLD}px) {
    > * + * {
      margin-left: 8px !important;
    }
  }
  display: flex;
  margin-right: 18px;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  height: 100%;
  > * + * {
    margin-left: 16px;
  }
`;

const TopRowRightSide = styled.div`
  @media (max-width: ${GAP_ADJUSTMENT_THRESHOLD}px) {
    > * + * {
      margin-left: 4px !important;
    }
  }
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  padding-left: 12px;
  padding-right: 24px;
  > * + * {
    margin-left: 12px;
  }
`;

const HorizontalSeparator = styled.div<{ isExpanded: boolean }>`
  width: 100%;
  height: ${HORIZONTAL_SEPARATOR_HEIGHT}px;
  background: linear-gradient(
    90deg,
    rgba(234, 238, 243, 0) 0%,
    var(--gray10) 10%,
    var(--gray10) 90%,
    rgba(234, 238, 243, 0) 100%
  );

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    height: 0.5px;
    background: linear-gradient(
      90deg,
      rgba(220, 226, 234, 0) 0%,
      var(--gray20) 10%,
      var(--gray20) 90%,
      rgba(220, 226, 234, 0) 100%
    );
  }
`;

const MergeDocsLogoSpan = styled.span`
  margin: 0;
  padding: 0 16px 0 0;
`;

const SubNavDiv = styled.div<{ isExpanded: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: ${(props) => (props.isExpanded ? SUB_NAV_HEIGHT : 0)}px;
  transition: ${HEIGHT_TRANSITION};
  margin: 0;
  padding: 0;
  width: 100%;
  display: flex;
  flex-grow: 1;
  overflow: hidden;
`;

const SubNavLeftSide = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 24px;
  height: 100%;
  > * + * {
    margin-left: 32px !important;
  }
`;

// Bottom border overlaps with the line below it
const SubNavLink = styled(Link)<{ $isActive?: boolean }>`
  text-decoration: none;
  margin: -1px 0;
  height: ${SUB_NAV_HEIGHT + 2}px;
  line-height: ${SUB_NAV_HEIGHT + 2}px;
  font-family: var(--font-family-sans-serif);
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 13px;
  color: ${(props) => (props.$isActive ? palette.black : palette.slate)} !important;
  border-bottom: ${(props) => (props.$isActive ? `2px solid ${palette.black}` : "none")};

  &:hover {
    color: #0b0b0d !important;
  }
`;

interface Props {
  location: RouterLocation;
  setIsInkeepVisible: Dispatch<SetStateAction<boolean>>;
}

/**
 * Element that shows the primary and secondary links for the page, based on the current
 * location. Has nice animated effects for when the secondary nav shows/hides when the
 * page location changes to a page without secondary nav. This is desktop only, see
 * `MobileNavigationBar` for the mobile version.
 */
const TopNavigationBar = ({ location, setIsInkeepVisible }: Props) => {
  const allNavLinks = useDocsNavLinks();
  const primaryLinks = useMemo(() => Object.values(allNavLinks), [allNavLinks]);
  const commandKPressed = useCommandKPressed();

  useEffect(() => {
    if (commandKPressed) {
      setIsInkeepVisible(true);
    }
  }, [commandKPressed]);

  const {
    primary: activePrimaryLink,
    secondary: activeSecondaryLink,
    isActive,
  } = useActiveDocsLink(location);
  const secondaryNavLinks = useMemo(
    () => activePrimaryLink.secondaryLinks,
    [activePrimaryLink.secondaryLinks],
  );
  const showsSecondaryNav = useMemo(
    () =>
      secondaryNavLinks !== undefined &&
      secondaryNavLinks.length > 0 &&
      !activePrimaryLink.hidesSecondaryLinks,
    [activePrimaryLink.hidesSecondaryLinks, secondaryNavLinks],
  );

  return (
    <>
      <DesktopOnlyNav aria-label="primary">
        <NavBarDiv isExpanded={showsSecondaryNav}>
          <TopRow>
            <TopRowLeftSide>
              <span className="pr-4">
                <Link to="/" title="Merge Documentation">
                  <MergeLogo />
                </Link>
              </span>
              {primaryLinks.map((primaryLink) => {
                // dont want to show these links on desktop but still need on mobile
                // decoupling these two would be a big task so conditonal for now
                if (primaryLink !== LINKS.HELP_CENTER && primaryLink !== LINKS.HOME) {
                  return (
                    <ChoiceLink
                      to={primaryLink.linkTo}
                      isActive={isActive(primaryLink)}
                      key={primaryLink.linkTo}
                    >
                      {primaryLink.text}
                    </ChoiceLink>
                  );
                }
              })}
              <NavigationDropDown
                navigationDropDownItems={[
                  {
                    text: LINKS.HELP_CENTER.text,
                    url: LINKS.HELP_CENTER.linkTo,
                  },
                  {
                    text: "Merge.dev",
                    url: MERGE_HOME_PAGE,
                  },
                  {
                    text: LINKS.CHANGELOG.text,
                    url: LINKS.CHANGELOG.linkTo,
                  },
                ]}
              />
            </TopRowLeftSide>
            <TopRowRightSide>
              <InkeepSearchWrapper
                placeholderText={"Search docs..."}
                showHotKeys={true}
                setIsInkeepVisible={setIsInkeepVisible}
              />
              <LinkButton rounded color="white" href="https://app.merge.dev/login">
                Log in
              </LinkButton>
              <LinkButton
                rounded
                color="black"
                href="https://www.merge.dev/get-in-touch"
                target="_blank"
              >
                Get a Demo
              </LinkButton>
            </TopRowRightSide>
          </TopRow>

          <HorizontalSeparator isExpanded={showsSecondaryNav} />

          <SubNavDiv isExpanded={showsSecondaryNav}>
            {showsSecondaryNav && secondaryNavLinks && (
              <>
                <SubNavLeftSide>
                  {secondaryNavLinks.map((secondaryLink) => (
                    <SubNavLink
                      to={secondaryLink.linkTo}
                      $isActive={secondaryLink.linkTo === activeSecondaryLink?.linkTo}
                      key={secondaryLink.linkTo}
                    >
                      {secondaryLink.text}
                    </SubNavLink>
                  ))}
                </SubNavLeftSide>
              </>
            )}
          </SubNavDiv>
        </NavBarDiv>
      </DesktopOnlyNav>

      {/* Return a placeholder div that does not have "position: fixed" to take up space "underneath" the nav. */}
      <NavBarCollapsableHeightPlaceholderDiv isExpanded={showsSecondaryNav} />
    </>
  );
};

export default TopNavigationBar;
