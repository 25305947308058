import LinkedInSvg from "assets/icons/linkedin-gray.svg";
import MediumSvg from "assets/icons/medium-gray.svg";
import TwitterSvg from "assets/icons/twitter-gray.svg";
import MergeLogo from "assets/svg/logos/merge/logo.svg";
import { BREAKPOINTS, COLORS } from "components/docs/assets/constants";
import { Link as GatsbyLink } from "gatsby";
import React, { Fragment } from "react";
import styled from "styled-components";
import useDocsFooterLinks from "../navigation/useDocsFooterLinks";
import DeprecatedH3 from "components/deprecated/DeprecatedH3";

const FooterDiv = styled.div`
  @media (min-width: ${BREAKPOINTS.XS}px) {
    padding: 65px 18px 95px 22px;
  }
  @media (min-width: ${BREAKPOINTS.MD}px) {
    padding: 65px 65px 95px 65px;
  }
  @media (min-width: ${BREAKPOINTS.XXL}px) {
    padding: 65px 103px 95px 103px;
  }

  margin: 0 1px;
  width: 100%;
  padding: 0;
  overflow: hidden;
  background: #ffffff;
  border-top: 1px solid var(--gray10);

  /* Retina */
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    border-top: 0.5px solid var(--gray20);
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const ColTitle = styled(DeprecatedH3)`
  && {
    height: 33px;
    width: 100%;
    font-size: 14px;
    line-height: 24px;
    color: #92959e;
    padding: 6px 0px;
    margin: 32px 0 12px 0;
  }
`;

const FlexRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: stretch;
`;

const FlexRowSpaceBetween = styled(FlexRow)`
  justify-content: space-between;
  align-items: center;
`;

const COL_PADDING_RIGHT = 32; // px
const Col = styled.div`
  @media (min-width: ${BREAKPOINTS.XS}px) {
    width: 100%;
  }
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: auto;
  }
  min-width: 200px;
  flex-grow: 1;
  padding: 0 ${COL_PADDING_RIGHT}px 0 0;
  margin: 0;
`;

const ColNoGrow = styled(Col)`
  flex-grow: 0;
`;

const ColSpacer = styled.div`
  @media (min-width: ${BREAKPOINTS.XS}px) {
    width: 100%;
  }
  @media (min-width: ${BREAKPOINTS.SM}px) {
    width: 32px;
  }
  height: 0;
`;

const FullWidthLine = styled.hr`
  && {
    border-top: 1px solid var(--gray10);

    /* Retina */
    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
      border-top: 0.5px solid var(--gray20);
    }

    margin: 0 0 8px;
    padding: 0;
  }
`;

/**
 * <hr /> with negative margin-right that can reach between columns.
 */
const FullWidthLineExtendRight = styled(FullWidthLine)`
  && {
    margin-right: -${COL_PADDING_RIGHT}px;
  }
`;

const InlineBlock = styled.div`
  @media (min-width: 1759px) {
    display: inline-block;
    vertical-align: top;
    width: 209px;
  }
`;

const TextLarge = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0;
`;

const TextSmall = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 20px;
  padding: 0 0 16px 0;
`;

const TextExtraSmall = styled.div`
  font-weight: normal;
  font-size: 13px;
  line-height: 15px;
  color: ${COLORS.LIGHT_GRAY};
`;

const Link = styled(GatsbyLink)`
  && {
    color: ${COLORS.BLACK};

    &:hover {
      color: ${COLORS.DARK_GRAY};
    }
  }
`;

const LinkLightGray = styled.a`
  && {
    color: ${COLORS.LIGHT_GRAY};

    &:hover {
      color: ${COLORS.DARK_GRAY};
    }
  }
`;

const ExternalLink = styled.a``;

const JustifyLeft = styled.div`
  display: flex;
  margin-top: 34px;
  margin-right: 12px;
  justify-content: left;
  > * + * {
    @media (min-width: ${BREAKPOINTS.XS}px) {
      margin-left: 14px;
    }
    @media (min-width: ${BREAKPOINTS.SM}px) {
      margin-left: 22px;
    }
    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin-left: 40px;
    }
  }
`;

const JustifyRight = styled(JustifyLeft)`
  justify-content: right;
  margin-right: 0;
  > * + * {
    @media (min-width: ${BREAKPOINTS.MD}px) {
      margin-left: 27px;
    }
  }
`;

const LinkedIn = styled(LinkedInSvg)`
  width: 20px;
  height: 20px;
`;

const Medium = styled(MediumSvg)`
  width: 20px;
  height: 20px;
`;

const Twitter = styled(TwitterSvg)`
  width: 20px;
  height: 20px;
`;

/**
 * Shows a footer across all pages of docs, with links in multiple columns split by
 * section.
 */
const Footer = () => {
  const footerLinkColumns = useDocsFooterLinks();
  const columnElements = footerLinkColumns.map(({ columnTitle, links, spaceAfter }) => {
    // If any child has too many sublinks, we show the column with multiple sub columns
    const hasManySublinks = links.some(({ links: sublinks }) => sublinks && sublinks.length > 18);

    const linkElements = links.map(({ text, linkTo, links: sublinks }) => {
      // Smaller links, might appear in two columns split into groups of 12
      const sublinkElements =
        sublinks &&
        sublinks.map((link) => (
          <TextSmall key={link.linkTo}>
            <Link to={link.linkTo}>{link.text}</Link>
          </TextSmall>
        ));
      return (
        <Fragment key={text}>
          <TextLarge>
            <Link to={linkTo}>{text}</Link>
          </TextLarge>
          {sublinkElements && hasManySublinks ? (
            <>
              <InlineBlock>{sublinkElements.slice(0, 11)}</InlineBlock>
              <InlineBlock>{sublinkElements.slice(11)}</InlineBlock>
            </>
          ) : (
            sublinkElements
          )}
        </Fragment>
      );
    });

    // Title, underline, links, and a spacer if we need it after
    return (
      <Fragment key={`${columnTitle}-${linkElements?.[0]?.key}`}>
        <Col as={hasManySublinks ? ColNoGrow : Col}>
          {columnTitle ? <ColTitle>{columnTitle}</ColTitle> : <ColTitle />}
          <FullWidthLineExtendRight />
          {linkElements}
        </Col>
        {spaceAfter && <ColSpacer />}
      </Fragment>
    );
  });

  return (
    <FooterDiv>
      <Container>
        <a href="https://www.merge.dev">
          <MergeLogo />
        </a>
        <FlexRow>{columnElements}</FlexRow>
        <FullWidthLine />
        <FlexRowSpaceBetween>
          <JustifyLeft>
            <TextExtraSmall>© Merge {new Date().getFullYear()}</TextExtraSmall>
            <TextExtraSmall>
              <LinkLightGray href="https://www.merge.dev/terms/">Terms of Use</LinkLightGray>
            </TextExtraSmall>
            <TextExtraSmall>
              <LinkLightGray href="https://www.merge.dev/privacy-policy/">
                Privacy Policy
              </LinkLightGray>
            </TextExtraSmall>
          </JustifyLeft>
          <JustifyRight>
            <ExternalLink href="https://medium.com/mergeapi" title="Merge Medium" target="__blank">
              <Medium />
            </ExternalLink>
            <ExternalLink
              href="https://twitter.com/merge_api"
              title="Merge Twitter"
              target="__blank"
            >
              <Twitter className="ml-6" />
            </ExternalLink>
            <ExternalLink
              href="https://www.linkedin.com/company/merge-api"
              title="Merge LinkedIn"
              target="__blank"
            >
              <LinkedIn className="ml-6" />
            </ExternalLink>
          </JustifyRight>
        </FlexRowSpaceBetween>
      </Container>
    </FooterDiv>
  );
};

export default Footer;
